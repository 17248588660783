<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(onSubmit)" a-tag="formChoosePassword">
      <b-form-group>
        <TextField
          v-model="form.password"
          rules="required|verify_password"
          :type="passwordType"
          :label="$t('LOGIN_NEW_PASSWORD')"
          :name="$t('LOGIN_NEW_PASSWORD')"
          class="p-relative d-block"
          ref="password"
        >
          <b-icon :icon="passwordIcon" @click="hideShowPassword" class="icon-password" />
        </TextField>
      </b-form-group>
      <b-form-group>
        <TextField
          v-model="form.confirmPassword"
          :rules="`required|confirmed:${$t('LOGIN_NEW_PASSWORD')}`"
          :type="confirmPasswordType"
          :label="$t('LOGIN_CONFIRM_PASSWORD')"
          :name="$t('LOGIN_CONFIRM_PASSWORD')"
          class="p-relative d-block"
        >
          <b-icon
            :icon="confirmPasswordIcon"
            @click="hideShowPassword('confirm')"
            class="icon-password"
          />
        </TextField>
      </b-form-group>
      <b-form-group>
        <Button
          type="submit"
          color="primary"
          :dBlock="true"
          customClass="w-100 text-uppercase"
          :loading="loading"
          a-tag="buttonChangePassword"
        >
          {{ $t("GLOBAL_SEND") }}
        </Button>
      </b-form-group>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { TogglePasswordMixins } from "@/mixins/TogglePasswordMixins";

export default {
  mixins: [TogglePasswordMixins],

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        password: null,
        confirmPassword: null,
      },
    };
  },
  methods: {
    onSubmit() {
      this.$emit("handleResetPassword", this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
