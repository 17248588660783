<template>
  <registeration-layout>
    <div class="custom-container">
      <div class="login-container">
        <div class="login-header">
          <router-link to="/">
            <img class="logo" src="@/assets/images/logo.svg" alt="" />
          </router-link>
        </div>
        <div class="row">
          <div class="col-lg-8">
            <h2 class="main-title">
              {{ $t("LOGIN_ENTER_YOUR") }}
              <span>
                {{ `${$t("LOGIN_NEW_PASSWORD")} ${$t("AND")} ${$t("LOGIN_CONFIRM_PASSWORD")}` }}
              </span>
            </h2>
            <img
              src="@/assets/images/forogt-password.png"
              class="img-fluid forgot-password-imgage"
              alt=""
            />
          </div>
          <div class="col-lg-4">
            <div class="login-body">
              <div class="forgot-password-container">
                <h6 class="form-title">
                  {{ $t("LOGIN_CHOOSE_NEW_PAASSWORD") }}
                </h6>

                <choose-new-password
                  :loading="loading"
                  @handleResetPassword="handleResetPassword"
                />
              </div>
              <!-- <div class="text-center">
                <router-link to="/login" class="back-link" a-tag="routeLogin">
                  {{ $t("GLOBAL_BACK") }}
                </router-link>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </registeration-layout>
</template>

<script>
import ChooseNewPassword from "@/components/Registeration/ChooseNewPassword";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ChooseNewPassword,
  },

  mounted() {
    if (!this.$route.query.token || !this.$route.query.email) {
      this.$router.push("/login");
    }
  },

  computed: {
    ...mapGetters(["loading"]),
  },
  methods: {
    ...mapActions(["ResetPassword"]),
    handleResetPassword(formData) {
      let query = this.$route.query;

      const body = {
        email: query.email,
        token: query.token,
        password: formData.password,
      };

      this.ResetPassword(body);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
